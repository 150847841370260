import {
  AiOutlineLinkedin
} from "react-icons/ai";
import { SiFiverr } from "react-icons/si";

const data = [
  { id: 1, link: "https://www.linkedin.com/in/glenntarpey/", icon: <AiOutlineLinkedin /> },
  { id: 2, link: "https://www.fiverr.com/doctorrobot?up_rollout=true", icon: <SiFiverr /> }
];

export default data;
