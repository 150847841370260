import { FaAward } from "react-icons/fa";
import { TbBooks } from "react-icons/tb";
import { BiHappyHeartEyes } from "react-icons/bi";

const data = [
  { id: 1, icon: <FaAward />, title: "Experience", desc: "10+ Years Working" },
  { id: 2, icon: <TbBooks />, title: "Projects", desc: "10+ Completed" },
  {
    id: 3,
    icon: <BiHappyHeartEyes />,
    title: "Clients",
    desc: (
      <>
        20+ happy clients
        <br />
        (Freelance and Professional)
      </>
    ),
  },
];

export default data;
